import React from "react";
import "../App.css";
import "./AboutSection.css";

function AboutSection() {
    return (
        <div className="welcome-container">
            <h1>Welcome to QCPAccounting</h1>
            <p>Every individual and business has different accounting needs, and we 
                are here for any and every financial challenge you may face. QCPAccounting is ready to serve 
                as a voice of reason from your tax planning and financial business demands to your insurance needs. <br /> <br />
                 We want to help you make the best decisions possible. Our personalized accounting services and solutions have helped our clients manage a better financial 
                 future. <br /> <br /> Our insurance services have helped our hard working clients protect their assets while saving money to use on expanding their business.
            </p>
        </div>
    )
}

export default AboutSection;
