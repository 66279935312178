import React, { Component } from "react";
import axios from "axios";
import "./Contact.css";

class Contact extends Component {
    state = {
        fullName: "",
        phoneNumber: "",
        email: "",
        message: "",
        sent: false
    }

    handleName = (e) => {
        this.setState({
            fullName: e.target.value
        });
    };

    handlePhonenumber = (e) => {
        this.setState({
            phoneNumber: e.target.value
        });
    };

    handleEmail = (e) => {
        this.setState({
            email: e.target.value
        });
    };

    handleMessage = (e) => {
        this.setState({
            message: e.target.value
        });
    };

    formSubmit = (e) => {
        e.preventDefault();

        let data = {
            name: this.state.fullName,
            phone: this.state.phoneNumber,
            email: this.state.email,
            message: this.state.message
        }

        axios.post("/send", data)
        .then(res => {
            this.setState({
                sent: true
            }, this.resetForm())
        }).catch(() => {
            console.log("Message not sent");
        });
    };

    resetForm = () => {
        this.setState({
            fullName: "",
            phoneNumber: "",
            email: "",
            message: "",
            sent: true
        })
    }

    render() {
        const { fullName, phoneNumber, email, message } = this.state;
        return (
            <div className="contact">
                <h1>Contact Us</h1>
                <div className="contact-container">
                    <form onSubmit={this.formSubmit} autoComplete="off"> 
                        <input className="input-box" value={fullName} onChange={this.handleName} placeholder="Full Name" type="text" name="name" />
                        <input className="input-box" value={phoneNumber} onChange={this.handlePhonenumber} placeholder="Phone Number" type="text" name="number" />
                        <input className="input-box" value={email} onChange={this.handleEmail} placeholder="Email" type="email" name="user_email" required />
                        <textarea className="message-box" value={message} onChange={this.handleMessage} placeholder="Message" type="text" name="message" />
                        <div className={this.state.sent ? "msg msgAppear" : "msg"}>Message has been sent.</div>
                        <button className="submit-btn" type="submit" value="submit">Submit</button>
                    </form>
                    <div className="map-container">
                        <img className="map" src="images/map.png" alt="" /> <br/>
                        <p>13624 HAWTHORNE BLVD SUITE 102</p> <br/>
                        <p>HAWTHORNE, CA 90250</p> <br/>
                        <a href="tel:4242970006">(424)297-0006</a> 
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;