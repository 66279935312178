import React from "react";
import "./Paralegal.css";

function Paralegal() {
    return (
        <div className="paralegal">
            <h1>Paralegal Services</h1>
            <div className="paralegal-container">
                <div className="card-row-one">
                    <div className="card">
                        <h2>Business Formation</h2>
                        <p>QCPAccounting can assist in your company’s business formation. During your consultation, our partners will discuss the benefits and complications of different formations to decided which works best for you and your company. We have a few different packages available depending on the size and needs of your business.</p>
                    </div>
                    <div className="card">
                        <h2>State Filings</h2>
                        <p>Many business owners do not know all the fees and filings associated with owning a business in California. Our team can assist in getting your state affairs in order including Secretary of State Filing, CA Department of Tax and Fee Administration (CDTFA) Filings, Articles of Incorporation, Statement of Information, and anything else you may need submitted to the state.</p>
                    </div>
                    <div className="card">
                        <h2>Immigration Assistance</h2>
                        <p>QCPAccounting has 10 years of experience assisting clients who need to get their immigration affairs in order. We understand this process is long and sometimes complicated, and we are here for you every step of the way. We can assist with DACA Renewals, submitting forms to immigration, translations, and much more.</p>
                    </div>
                    <div className="card">
                        <h2>Loan Application Assistance</h2>
                        <p>Owning a business always has its ups and downs. Sometimes loans are required for the ups, but more often than that you may need to acquire more funds for the downs. We can assist with applications for funding like SBA Loans, PPP Loans, and even local government programs. After we help you get the funds, we can even help you with the Forgiveness Applications as well.</p>
                    </div>
                </div>
                {/* <div className="card-row-two">
                    
                </div> */}
            </div>
        </div>
    )
};

export default Paralegal;