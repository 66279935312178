export const MenuItems = [
    {
        title: "Tax",
        path: "/tax",
        cName: "dropdown-link"
    },
    {
        title: "Bookkeeping",
        path: "/bookkeeping",
        cName: "dropdown-link"
    },
    {
        title: "Insurance",
        path: "/insurance",
        cName: "dropdown-link"
    },
    {
        title: "Paralegal",
        path: "/paralegal",
        cName: "dropdown-link"
    },
    {
        title: "Notary - COMING SOON!",
        path: "/",
        cName: "dropdown-link"
    }
]