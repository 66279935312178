import React from "react";
import "../App.css";
import "./HeroSection.css";

function HeroSection() {
    return (
        <div className="hero-container">
            <p>LET US HANDLE YOUR BACK <br/> OFFICE <br/> WHILE YOU GROW YOUR <br/> BUSINESS</p>
        </div>
    )
}

export default HeroSection;